















































































































.deposit-coin {
  min-width: 450px;
  margin: auto;
  width: 100%;
  padding: 1em;
  background-color: #0c83e9;
  .qr-code {
    text-align: center;
    img {
      max-width: 215px;
      margin: auto;
      width: 100%;
      margin-top: 10px;
    }
  }
  .title {
    font-weight: 600;
    font-size: clamp(16px, 2vw, 22px);
    font-weight: bold;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    padding: 0 0 15px;
    border-bottom: 3px solid rgba($color: #000000, $alpha: 0.2);
  }
  .coin-address {
  }
  .button-close {
    button {
      font-weight: 600;
    }
  }
  .coin-warning {
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
    font-size: clamp(14px, 2vw, 16px);
    color: #ffffff;
  }
  label {
    font-size: clamp(14px, 2vw, 16px);
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 2px;
  }
  .box-address {
    width: 100%;
    span {
      font-size: clamp(14px, 2vw, 16px);
      color: #ffffff;
      font-weight: 600;
    }
    .address-wallet {
      margin-top: 2px;
      background: #000597b8;
      border-radius: 5px;
      .input-group-text {
        padding: 0;
        border: 0;
        button {
          width: 70px;
          height: 35px;
          padding: 0;
          background: #000597;
          border: 0;
          outline: none;
          box-shadow: 0px 0px 5px 1px #0000009e;
          font-weight: 600;
        }
      }
      input {
        height: 35px;
        border: 0;
        background: transparent;
        color: #fff;
        font-weight: 600;
        &:hover,
        &:focus,
        &:focus-visible {
          outline: none;
          box-shadow: none;
          border: 0;
        }
      }
    }
  }
}
