





























































































































































































































































































































































































































#deposit,
#withdraw,
#transfer {
  .modal-dialog {
    width: 100%;
    .modal-content {
      max-width: 375px;
      margin: auto;
    }
  }
}
#create-live {
  .form-title {
    border-bottom: 3px solid #00000333;
    h4 {
      color: #2f394e;
      font-weight: 600;
      font-size: 18px;
    }
  }
  .form-group {
    label {
      font-weight: 600;
      color: #6c757d;
      font-size: 16px;
      margin-bottom: 0px;
    }
    select,
    input {
      border: 0;
      border-radius: 0;
      border-bottom: 2px solid #6c757d;
      height: 40px;
      font-size: clamp(15px, 1vw, 16.2px);
      font-weight: 600;
      &:focus {
        box-shadow: none;
        border-bottom: 2px solid #097501;
      }
      &::placeholder {
        font-weight: 500;
        font-size: clamp(13px, 1vw, 15px);
      }
    }
  }
}
.box-list-account {
  width: 100%;
  margin-top: 50px;
  min-height: 200px;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #00000333;
  position: relative;
  margin-bottom: 1.25rem;
  .list-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 0 0;
    text-align: center;
    position: relative;
    button {
      font-weight: 600;
      position: absolute;
      top: -50%;
      width: 100%;
      max-width: 210px;
    }
  }
  .list-account {
    padding: 0 1.25rem 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .box-account {
      flex: 0 0 25%;
      max-width: 25%;
      &.rebate {
        .account-box {
          border: 3px solid #0d9881;
          .account-box-header {
            padding: 0px 0 10px;
            border-radius: 7px 7px 0 0;
            background: #009750;
            .box-detail-header {
              &.one {
                margin-top: 5px;
                .box-balance {
                  width: 100%;
                  text-align: center;
                }
              }
            }
          }
          .account-box-footer {
            button {
              max-width: 80px;
              min-width: 77px;
            }
          }
        }
      }
      .account-box {
        box-shadow: 0px 0px 10px 2px #00000038;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        margin: 0 auto 15px;
        position: relative;
        width: calc(100% - 20px);
        flex-direction: column;
        .account-box-header {
          background-image: url(~@/assets/images/background/overlay.png);
          padding: 15px 0;
          border-radius: 10px 10px 0 0;
          width: 100%;
          .name-account {
            color: #fff;
            font-weight: 600;
            font-size: clamp(16px, 2vw, 20px);
            text-transform: uppercase;
          }
          .setting-account {
            background: transparent;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #fff;
          }
          .box-detail-header {
            display: flex;
            justify-content: space-between;
            .box-balance {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              margin-top: 10px;
              .value {
                font-weight: 600;
                color: #ffff;
                font-size: 20px;
                line-height: 120%;
                text-align: center;
              }
              .title {
                font-weight: 400;
                color: #ffff;
                letter-spacing: 1px;
                opacity: 0.9;
                text-transform: capitalize;
                font-size: clamp(14px, 2vw, 17px);
                line-height: 100%;
              }
            }
            .type-account {
              width: max-content;
              .value {
                font-size: 17px;
              }
              .title {
                font-size: clamp(13px, 2vw, 15px);
              }
            }
          }
        }
        .account-box-body {
          width: 100%;
          padding: 15px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          align-content: space-around;
          align-items: stretch;
          height: 100%;
          .detail-account {
            min-width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 10px auto;
            .title {
              font-weight: 600;
              color: #2f394e;
              opacity: 0.9;
              text-align: center;
              text-transform: capitalize;
              font-size: clamp(11px, 2vw, 10px);
            }
            .value {
              font-weight: 700;
              font-size: 20px;
              color: #2f394e;
              text-align: center;
              font-size: clamp(16px, 2vw, 18px);
            }
            &.account {
              width: 100%;
            }
            &.platform {
            }
            &.leverage {
            }
          }
        }
        .account-box-footer {
          width: 100%;
          border-top: 2px solid rgba(0, 0, 0, 0.1);
          padding: 15px 5px;
          border-radius: 0 0 15px 15px;
          display: flex;
          justify-content: space-around;
          button {
            min-width: unset;
            width: auto;
            padding: 5px;
            flex: 1;
            // max-width: 100px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
          }
        }
      }
    }
    @media (max-width: 1600px) {
      .box-account {
        flex: 0 0 33.3%;
        max-width: 33.3%;
      }
    }
    @media (max-width: 991px) {
      .box-account {
        flex: 0 0 50%;
        max-width: 50%;
        .account-box {
          width: calc(100% - 10px);
          max-width: 320px;
        }
      }
    }
    @media (max-width: 767px) {
      .box-account {
        flex: 0 0 100%;
        max-width: 100%;
        .account-box {
          width: 100%;
          max-width: 320px;
        }
      }
    }
  }
}
